import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './pages/LandingPage.tsx';
import Temp from './pages/Temp.tsx';
import TypeRacer from './pages/typeracer/TypeRacer.tsx';
import ProjectDetail from './pages/projects/ProjectDetail.tsx';
import { AnimatePresence } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import Navbar from './components/Navbar.tsx';

const AppWrapper = () => {
  return (
    <Router>
      <Navbar />
      <App />
    </Router>
  );
};

const App = () => {
  const location = useLocation(); 

  return (
    <AnimatePresence mode='wait'>
      <Routes key={location.pathname} location={location}>
        <Route path="/" element={<LandingPage />} />
        <Route path="/typeracer" element={<TypeRacer />} />
        <Route path="/projects" element={<ProjectDetail />} />
      </Routes>
    </AnimatePresence>
  );
};

export default AppWrapper;
