import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../style/title.css';
import { faExternalLinkAlt, faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import ParticlesComponent from '../components/particles';
import Projects from '../components/Projects.tsx';
import transition from '../lib/transition.js';


const LandingPage: React.FC = () => {
  const location = useLocation();

  useLayoutEffect(() => {
    if (location.state && location.state.section) {
      const sectionId = location.state.section;
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [location.state]);

  const containerStyle = {
    backgroundImage: `url('/images/background/layered-waves.svg')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
  };

  const containerStyle2 = {
    backgroundImage: `url('/images/background/layered-waves-2.svg')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center', 
  };
  

  const containerStyle3 = {
    backgroundImage: `url('/images/background/layered-waves.svg')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  };

  const containerStyle4 = {
    backgroundImage: `url('/images/background/layered-waves-2.svg')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
  };

  return (
    <div className="min-h-screen text-gray-200">

      <div className="h-[75vh] md:h-[100vh] w-full relative">
        <ParticlesComponent className="absolute inset-0 z-0" />
        <h1 className="container font-bold text-center md:text-left md:px-72 px-6 text-5xl highlight relative z-10">WELCOME OUTSIDER</h1>
        <div className="absolute bottom-0 left-0 w-full h-[23%] z-5 overflow-hidden">
          <img src="/images/background/layered-waves.svg" alt="Layered Waves" className="w-full h-full object-cover object-bottom transform" />
        </div>
      </div>

      <div style={containerStyle2} className="h-[60vh] md:h-[50vh] w-full relative z-10 ">
        <section id="about" className="mx-auto pt-16 md:pt-20 text-center">
          <h2 className="text-3xl font-bold mb-8">About Me</h2>
          <div className="flex flex-col items-center">
            <p className="text-white text-3xl md:text-5xl font-bold">
              MUHAMMAD <a className='text-orange-400'>ARIEF</a>
            </p>
            <h5 className="text-white text-3xl md:text-5xl font-bold mb-4 md:mb-14 md:hidden"><a className='text-orange-400'>BRAJA </a>PUTRA</h5>
            <div className="flex flex-col md:flex-row items-center">
              <img src="/images/default-profile.jpg" alt="Profile Picture" className="rounded-full w-36 h-36 md:w-52 md:h-52 object-cover shadow-md mx-4" />
              <p className='text-start'>
                <h5 className="text-white text-3xl md:text-5xl font-bold mb-4 md:mb-14 hidden md:block"><a className='text-orange-400'>BRAJA </a>PUTRA</h5>
                <h5 className='text-white text-xl md:text-2xl mt-4 md:mt-0'>i just like to code </h5>
                <h5 className='text-white text-xl md:text-2xl mb-12'>and explore new things :D</h5>
              </p>
            </div>
          </div>
        </section>
      </div>

      <div style={containerStyle3} className="h-[75vh] lg:h-[70vh] w-full relative z-10">
        <section id="projects" className="mx-4 2xl:mx-80 p-6">
         <Projects />
        </section>

      </div>

      <div style={containerStyle4} className="h-[45vh] lg:h-[25vh] w-full relative z-10">
        <section id="contact" className="container mx-auto px-4 pt-4 sm:pt-16">
          <h2 className="text-3xl font-bold text-center mt-8 md:mt-0">Contact Me</h2>
          <div className="text-center mt-4">
            <a href="https://www.linkedin.com/in/ariefbraja" 
              target="_blank"
              className="block mb-2 text-blue-500 hover:text-blue-700 font-semibold text-xl">
              LinkedIn
            </a>
            <a href="mailto:marief.braja@gmail.com" 
              className="text-blue-500 hover:text-blue-700 font-semibold text-xl">
              Email
            </a>
          </div>
        </section>
      </div>
    </div>
  );
};

export default transition(LandingPage);
