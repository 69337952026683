import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate(); 

  const handleNavigation = (section) => {
    if (location.pathname === '/') {
      scrollToSection(section);
    } else {
      // Navigate and pass the section as state
      navigate('/', { state: { section } });
    }
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <header className="bg-gray-900 shadow-md sticky top-0 z-40">
      <div className="container mx-auto p-6 flex justify-between items-center">

        <img src="/images/cgfed-logo.png" className='w-8 hover:cursor-pointer' onClick={() => navigate("/")}/>
        <nav>
          <ul className="flex space-x-4">
            <li><button onClick={() => navigate('/typeracer')} className="text-gray-300 hover:text-blue-400">Typeracer</button></li>
            <li><button onClick={() => handleNavigation('about')} className="text-gray-300 hover:text-blue-400">About</button></li>
            <li><button onClick={() => handleNavigation('projects')} className="text-gray-300 hover:text-blue-400">Projects</button></li>
            <li><button onClick={() => handleNavigation('contact')} className="text-gray-300 hover:text-blue-400">Contact</button></li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
