import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from 'react-router-dom';
import { GlobeIcon } from '../lib/utils.tsx';
import projects from '../lib/project.ts';

interface Project {
  name: string;
  description: string;
  imageUrl: string;
  link?: string;
  width: string;
  tech: string[];
  info?: string[];
}

const Projects = () => {

  const settings = {
    dots: false,
    infinite: true,
    speed: 10000,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: true,
    centerPadding: '0px',
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '0px',
        },
      },
    ],
  };

  const navigate = useNavigate();
  
  return (
    <div >
      <div className="text-3xl font-bold mb-4 mt-8 md:mt-0 text-center lg:text-left lg:ml-4">
        <div>
          <div className='relative flex'>
            <div>
              My Projects
            </div>
            <div className='ml-4'>
              <FontAwesomeIcon icon={faExternalLinkAlt} size="sm" className='text-blue-400 hover:cursor-pointer' onClick={() => navigate("/projects")}/>
            </div>
          </div>
        </div>
      </div>
      <Slider {...settings}>
        {projects.map((project, index) => (
          <div key={index} className="px-4">
           <div className="bg-gray-800 shadow-md h-full md:h-96 xl:h-80 w-full md:w-[calc(550px)] p-4 rounded-lg relative">
              <div className='flex-grow'>
                {/* {project.link && (
                  <a href={project.link} target="_blank" rel="noopener noreferrer" className="absolute top-2 right-2 text-blue-400 hover:text-blue-600 mt-4 mr-4" onClick={(e) => e.stopPropagation()}>
                    <FontAwesomeIcon icon={faExternalLinkAlt} size="xl" />
                  </a>
                )} */}
                <img src={project.imageUrl} alt={`${project.name} Logo`} className={`${project.width} h-16 ml-6 mt-4`} />
                <div className="ml-6">
                  <h3 className="text-xl font-semibold mb-2 mt-2">{project.name}</h3>
                  <p className="text-white mb-2 text-justify mr-8">{project.description}</p>
                </div>
              </div>
              <div className='absolute bottom-0 left-0 mb-4 ml-4'>
                <div className='ml-6 flex flex-row flex-wrap items-start gap-1'>
                  {project.tech ? (
                    project.tech.map((tech, idx) => (
                      <span key={idx} className="inline-flex items-center rounded-md border px-2.5 py-1 
                        text-xs transition-colors focus:outline-none focus:ring-2 focus:ring-ring 
                        focus:ring-offset-2 border-transparent bg-gray-700 text-primary-foreground shadow hover:bg-primary/80">
                        {tech}
                      </span>
                    ))
                  ) : (
                    <span />
                  )}
                </div>
                <div className='mt-2 ml-6 flex flex-row flex-wrap items-start gap-1'>
                  {project.info ? (
                    project.info.map((info, idx) => (
                      <span key={idx} className="inline-flex items-center rounded-md border px-2.5 py-1 
                        text-xs transition-colors focus:outline-none focus:ring-2 focus:ring-ring 
                        focus:ring-offset-2 border-transparent bg-gray-950 text-primary-foreground shadow hover:bg-primary/80">
                        <GlobeIcon className="h-4 w-4 mr-1" />
                        {info}
                      </span>
                    ))
                  ) : (
                    <span />
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Projects;
