export const stories = [
    "axe once a leader of the red mist army is known for his brutal strength and fearless tactics his uncontrollable bloodlust led him to slaughter his own soldiers for power now axe roams the battlefield alone seeking glory through combat his unmatched ferocity and insatiable hunger for battle make him a relentless warrior feared by all who cross his path his brutal nature is legendary and his name is synonymous with chaos and destruction in the annals of war",
    "lifestealer once a powerful warlord named naix was imprisoned and driven mad by necromantic magic he gained the ability to infest and control enemies using their life force to sustain himself now a feral predator lifestealer unleashes fury on the battlefield tearing through foes with savagery driven by madness and a thirst for blood his relentless attacks and unquenchable hunger make him a force of nature leaving only devastation in his wake wherever he goes",
    "muerta the revenant gunslinger is a spectral figure from the carrion fields of gorm tales of her vengeance spread fear among those who hear them once a simple peasant girl she returned from death to punish those who wronged her family armed with ghostly firearms muerta hunts her enemies ensuring no crime goes unpunished her relentless pursuit of justice from beyond the grave makes her a fearsome adversary haunting the nightmares of all who have wronged her",
    "dark willow known as mireska fled her noble home to embrace a life of adventure and mischief armed with fey magic and a mischievous spirit she creates bramble mazes and unleashes fearsome curses on her foes despite her playful demeanor dark willow's powers are formidable making her a dangerous foe on the battlefield her ability to weave illusions and trick her enemies adds a layer of unpredictability to her already potent magic making her master of deception",
    "kunkka the admiral commands the ghostly tidehunter fleet haunted by the spirits of his fallen crew he battles to protect his homeland from invaders with his mighty sword and control over the tides kunkka summons torrents of water to devastate enemies ensuring the spirits of his crew find peace through his victories his unwavering determination and mastery of the seas make him a formidable protector of his people and a relentless avenger of his lost comrades",
    "legion commander tresdin leads with honor and vengeance after her regiment was destroyed by demons she vowed to eradicate the hellish invaders wielding a powerful blade and unmatched combat skills tresdin inspires her allies and challenges enemies to duels seeking to restore glory and justice to her fallen comrades her relentless pursuit of vengeance and her unwavering commitment to her cause make her a beacon of hope and a terror to those who oppose her",
    "lina the slayer possesses fiery powers inherited from her family's magical lineage with flames at her command she incinerates her enemies and lights up the battlefield with her destructive spells lina's fiery spirit and unmatched pyromancy make her a force to be reckoned with blazing a path of destruction in her wake her ability to control and unleash fire with precision and power makes her a truly fearsome opponent and a legend in her own right",
    "lion once a grandmaster of the demon witch was corrupted by his thirst for power transformed into a demonic entity he now wields dark magic to ensnare and destroy his enemies with his cursed hand lion drains the life force of his foes reveling in their suffering as he gains more power his descent into darkness and his relentless pursuit of power have made him a creature of nightmare feared and reviled by all who encounter him",
    "luna the moon rider fights under the divine blessing of the moon goddess selemene once a feared warrior she now leads the nightsilver woods defenders wielding a powerful glaive and summoning lunar energy to smite her enemies luna's devotion to selemene fuels her quest to protect the sacred forests her radiant power and unwavering commitment to her goddess make her a beacon of hope for her allies and a devastating force against her enemies",
    "mirana the princess of the moon rides into battle with the power of the stars accompanied by her sacred feline companion she fires arrows imbued with celestial energy striking down enemies from afar mirana's grace and precision make her a formidable archer guided by the constellations to victory her connection to the celestial forces and her unmatched archery skills make her a true champion of the moon and a terror to those who stand against her",
    "necrophos the plague rider was once a healer who turned to dark arts to survive a deadly plague now an undead harbinger of disease he spreads death and decay wherever he goes necrophos' malevolent magic drains the life from his enemies leaving behind a trail of pestilence and despair his transformation from healer to harbinger of death is a tale of tragedy and corruption making him a relentless force of destruction and suffering on the battlefield",
    "nyx assassin serves the goddess nyx executing her will with deadly precision chosen for his unparalleled stealth and lethal skills he eliminates targets with swift and silent strikes nyx assassin's unwavering loyalty to his goddess drives him to carry out his missions without hesitation ensuring the enemies of nyx are vanquished his ability to strike from the shadows and his lethal efficiency make him a true master assassin feared by all who know his name",
    "omniknight purist thunderwrath wields holy powers to protect and heal his allies a devout follower of the omniscience he channels divine energy to smite his enemies and shield his comrades from harm omniknight's unwavering faith and martial prowess make him a stalwart defender on the battlefield his dedication to his cause and his ability to harness divine power make him a beacon of hope and a formidable opponent against the forces of darkness",
    "phantom assassin mortred chosen by the veiled sisters executes marked targets with deadly efficiency her mastery of stealth and precision allows her to strike from the shadows leaving no trace of her presence mortred's unwavering focus on her missions makes her a feared assassin fulfilling the will of the veiled sisters her lethal skills and her ability to remain unseen make her a true master of assassination feared by all who find themselves on her list",
    "pudge the butcher revels in the slaughter dragging enemies to their doom with his fearsome hook in the fields of endless carnage pudge dismembers and disembowels his victims clearing the battlefield of the fallen his gruesome deeds and insatiable hunger for flesh make him a terror to behold his relentless pursuit of bloodshed and his terrifying presence make him one of the most feared and reviled figures on the battlefield",
    "riki the stealth assassin uses his agility and invisibility to strike from the shadows trained by the hidden ones he eliminates targets with silent precision leaving no evidence of his presence riki's mastery of stealth and deadly skills make him a formidable foe capable of turning the tide of battle his ability to move unseen and strike without warning make him a true master of assassination feared by all who know of his deadly skills",
    "shadow fiend nevermore collects the souls of those he defeats growing more powerful with each kill his presence on the battlefield is marked by an aura of fear and darkness as he unleashes devastating spells and relentless attacks shadow fiend's insatiable hunger for souls drives him to conquer all who oppose him his ability to harness the power of the souls he collects makes him a terrifying and relentless force of destruction on the battlefield",
    "silencer nortrom silences his enemies preventing them from casting spells and thwarting their plans a former librarian turned warrior he uses his arcane knowledge to suppress his foes' magic silencer's ability to control the flow of battle through silence makes him a crucial asset in any conflict his mastery of arcane magic and his ability to disrupt his enemies' strategies make him a formidable opponent and a valuable ally in any battle",
    "slardar the slithereen guard protects the underwater realm from threats with his immense strength emerging from the depths he uses his trident and brute force to crush enemies slardar's loyalty to his underwater kingdom drives him to safeguard its secrets and ensure its dominance over the oceans his relentless dedication to his people and his formidable strength make him a true guardian of the depths feared by all who threaten his realm",
    "sniper kardel sharpeye is a crack shot taking down enemies from incredible distances a master marksman from the keenfolk he uses his long range rifle to eliminate threats before they can get close sniper's precision and deadly aim make him a feared presence on the battlefield striking fear into his enemies his unmatched skill with a rifle and his ability to strike from afar make him a true master marksman and a terror to all who oppose him",
    "spectre mercurial is a haunting presence capable of manifesting across the battlefield to strike fear into her foes as a being of pure energy she moves through the ethereal plane hunting down those who dare to oppose her spectre's ability to instill terror and confusion makes her a formidable adversary her relentless pursuit of her enemies and her ability to move through the ethereal plane make her a truly terrifying force on the battlefield",
    "sven the rogue knight seeks to honor his father's legacy by upholding a code of chivalry and strength armed with a powerful sword and clad in heavy armor he charges into battle to protect the innocent and punish the wicked sven's unwavering sense of justice drives him to fight with honor his dedication to his code and his formidable combat skills make him a true knight in shining armor feared by all who oppose his righteous fury",
    "templar assassin lanaya studies the hidden mysteries of the universe striking from the shadows with psionic blades as a disciple of the hidden temple she uses her keen intellect and deadly precision to uncover ancient secrets and eliminate those who threaten her order lanaya's mastery of psionics makes her a dangerous opponent her ability to strike from the shadows and her relentless pursuit of knowledge make her a true master of the hidden arts",
    "terrorblade the demon marauder is a reflection of evil able to transform and steal the life of his enemies once a feared warlord he was imprisoned in the foulfell prison where he honed his dark powers terrorblade's ability to manipulate souls and unleash demonic fury makes him a terrifying force on the battlefield his relentless pursuit of power and his ability to transform into a demonic entity make him a true nightmare to all who oppose him",
    "tidehunter leviathan roams the oceans seeking revenge against the humans who wronged him with his massive anchor and control over the tides he summons tsunamis and crushes enemies with his immense strength tidehunter's quest for vengeance drives him to unleash the fury of the seas upon his foes his mastery of the ocean and his relentless pursuit of revenge make him a true force of nature feared by all who stand against him",
    "timbersaw rizzrack builds and operates a fearsome mechanized suit to cut through the forests and his foes once a peaceful inventor he became obsessed with clearing the forest after a traumatic encounter with treants timbersaw's powerful saw blades and engineering genius make him a relentless force of destruction his unyielding determination and his relentless pursuit of his goals make him a true master of destruction feared by all who stand in his way",
    "tinker boush uses his engineering genius to create powerful machines and devastating weaponry a master inventor from the keenfolk he constantly upgrades his arsenal to outsmart and outfight his enemies tinker's ability to adapt and innovate on the battlefield makes him a formidable and unpredictable adversary his relentless pursuit of technological advancement and his ability to outthink his opponents make him a true master of invention and warfare",
    "tiny the stone giant grows larger and stronger as he collects more stones to his form once a small rock golem he roams the mountains seeking to become the largest and mightiest creature tiny's immense strength and ability to hurl boulders make him a powerful presence on the battlefield his relentless pursuit of growth and his unyielding determination make him a true force of nature feared by all who stand in his way",
    "treant protector rooftrellen is the guardian of the forest using his powers to protect nature and its allies as a living embodiment of the ancient woods he commands the trees to ensnare enemies and heal his allies treant protector's connection to the natural world makes him a steadfast defender of the wild his unyielding dedication to the protection of nature and his formidable powers make him a true guardian of the forest",
    "undying dirge spreads a plague of undeath raising zombies to fight alongside him once a healer he was cursed with eternal life becoming a harbinger of decay undying's dark magic and unyielding endurance make him a relentless force on the battlefield overwhelming enemies with his undead horde his transformation from healer to harbinger of death is a tale of tragedy and corruption making him a relentless force of destruction and suffering on the battlefield",
    "ursa the warrior is a fierce protector of his ursine tribe driven to rage against those who threaten his kin with his powerful claws and unrelenting strength he charges into battle tearing through enemies with ferocious swipes ursa's bond with his tribe fuels his fury making him a formidable warrior his ability to unleash his primal rage in battle makes him a terrifying force against all who threaten his family",
    "venomancer lesale spreads venom and plague poisoning his enemies with deadly toxins once a naturalist he was transformed by his own experiments becoming a living embodiment of venom venomancer's toxic attacks and ability to create poisonous fields make him a lethal presence on the battlefield his relentless pursuit of his enemies and his mastery of toxins make him a true harbinger of death feared by all who encounter his deadly touch",
    "viper the netherdrake flies from the nether reaches to spread poison and chaos once an ordinary drake he was transformed by the toxic environment of the nether reaches viper's corrosive attacks and flight capabilities make him a dangerous adversary capable of overwhelming enemies with his venomous assault his transformation into a venomous beast and his unyielding aggression make him a true force of nature feared by all who face his deadly poison",
    "warlock demnok lannik summons demonic entities to fight alongside him and rain destruction on his enemies a master of dark magic he uses his powers to control the battlefield summoning golems and casting powerful curses warlock's ability to manipulate dark forces makes him a formidable spellcaster his mastery of summoning and his ability to rain destruction on his enemies make him a true master of dark magic feared by all who oppose him",
    "windranger lyralei harnesses the power of the wind to strike down enemies with unmatched precision a master archer she uses her agility and speed to outmaneuver foes firing arrows with deadly accuracy windranger's connection to the wind and her archery skills make her a swift and lethal combatant her ability to harness the power of the wind and her deadly accuracy make her a true master of the bow feared by all who stand in her path",
    "winter wyvern auroth is a learned dragon who uses her icy powers to freeze and control the battlefield with a vast knowledge of ancient lore she casts spells that chill and immobilize enemies creating opportunities for her allies winter wyvern's mastery of ice magic makes her a formidable support hero her ability to control the battlefield with her icy powers and her knowledge of ancient lore make her a true master of the cold feared by all who face her",
    "witch doctor zharvakko channels voodoo magic to heal allies and curse enemies a practitioner of dark arts he uses his spells to inflict pain and restore health creating a balance of life and death witch doctor's voodoo abilities and versatility make him a valuable asset in any conflict his mastery of dark arts and his ability to balance life and death with his spells make him a true master of voodoo feared by all who encounter his dark magic",
    "wraith king ostarion returned from death ruling as an immortal king with a legion of undead once a powerful ruler he was resurrected by dark magic gaining control over the forces of death wraith king's ability to revive and command undead minions makes him a daunting presence on the battlefield his mastery of dark magic and his command over the undead make him a true king of the dead feared by all who face his immortal wrath",
    "zeus the lord of heaven wields the power of lightning to smite his foes from the skies as a god he commands thunderbolts and divine energy to devastate enemies asserting his dominance over mortals zeus's overwhelming power and celestial might make him a force to be reckoned with his ability to command the heavens and unleash divine wrath make him a true god of thunder feared by all who stand against his celestial power",
    "dragon knight davion channels the power of a dragon transforming into a fearsome beast in battle a noble warrior he seeks to protect the weak and uphold justice using his dragon form to unleash fiery attacks dragon knight's dual nature as a human and dragon makes him a versatile and powerful hero his ability to transform into a dragon and his unwavering sense of justice make him a true champion feared by all who oppose his fiery might",
    "ember spirit xin fights with the burning spirit of fire mastering martial arts and fiery attacks a guardian of the sacred flame he uses his agility and skill to defeat enemies with blazing strikes ember spirit's mastery of fire and combat prowess make him a dynamic and formidable fighter his ability to harness the power of fire and his unmatched martial skills make him a true master of flames feared by all who face his fiery wrath",
    "invoker the arsenal magus wields an array of spells each more powerful than the last a master of arcane arts he combines elements to create devastating effects adapting his strategy to counter his enemies invoker's vast repertoire of spells and magical knowledge make him one of the most versatile and dangerous heroes his mastery of the arcane and his ability to adapt to any situation make him a true master of magic feared by all who stand against him"
];
