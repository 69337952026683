interface Project {
    name: string;
    description: string;
    imageUrl: string;
    link: string;
    width: string;
    tech: string[];
    info: string[];
  }
  
  const projects: Project[] = [
    {
      name: "EXISmart",
      description: "As the lead programmer, developed EXISmart for a tutoring center in Padang, West Sumatra to digitalize their administration and financial record-keeping processes.",
      imageUrl: "/images/exis-logo.jpg",
      link: "https://exismart.vercel.app/",
      width: "w-28",
      tech: ["React", "Node.js", "Express", "PostgreSQL", "Typescript"],
      info: ["Website"]
    },
    {
      name: "SOStainable",
      description: "A mobile app that connects volunteers with organizers of environmental cleanup events. This platform enables individuals to join and contribute to local efforts in maintaining cleaner and healthier public spaces.",
      imageUrl: "/images/sostainable.png",
      link: "https://github.com/ariefbraja/sostainable-backend/",
      width: "w-42",
      tech: ["Google Cloud Platform", "Node.js", "Express", "PostgreSQL"],
      info: ["Mobile Application"]
    },
    {
      name: "Depok Smart City",
      description: "Depok Smart City is a virtual representation of the city of Depok. The project includes a web application and a mobile application to provide various city services and information to residents.",
      imageUrl: "/images/flutter-django.png",
      link: "https://depoksmartcity.vercel.app/",
      width: "w-28",
      tech: ["Django", "Flutter"],
      info: ["Website"]
    },
    {
      name: "Discord Bot",
      description: "I created a Discord bot as a fun side project. The bot can handle various tasks, including fun chats, playing music, and providing useful commands to enhance the server experience.",
      imageUrl: "/images/discord-bot.png",
      link: "",
      width: "w-42",
      tech: ["Python"],
      info: ["Discord Bot"]
    },
    {
      name: "Cancer Detection",
      description: "I developed a side project for cancer detection in the Bangkit program that utilizes machine learning to identify cancer from photos. The project involved converting the cancer detection model and integrating it into the backend system.",
      imageUrl: "/images/cancer-detection.png",
      link: "https://github.com/ariefbraja/cancer-detection",
      width: "w-42",
      tech: ["Machine Learning", "JavaScript", "Google Cloud Platform"],
      info: ["Cloud Project"]
    },
    {
      name: "Travel Agency Chatbot",
      description: "I created a chatbot with LLM with retrieval augmented generation (RAG) to give some context for the chatbot LLM.",
      imageUrl: "/images/chatbot-logo.png",
      link: "https://travel-agency-chatbot.vercel.app/",
      width: "w-42",
      tech: ["Machine Learning", "RAG", "Next.js", "Supabase Vector Database"],
      info: ["Chatbot"]
    }
  ];
  
  export default projects;
  