import React, { useEffect } from 'react';
import { GlobeIcon, GitHubIcon } from '../../lib/utils.tsx';
import projects from '../../lib/project.ts';
import ParticlesComponent from '../../components/particles.js';
import transition from '../../lib/transition.js';


const ProjectDetail = () => {

    window.scrollTo(0, 0);
    
  return (
    <div className='min-h-screen bg-main-colour'>
      <div className='h-2'>
        <ParticlesComponent className='h-2'/>
      </div>

      <div className='mx-4 2xl:mx-[350px] p-6 text-white relative z-30'>
        {projects.map((project, index) => (
          <div key={index} className="px-4">
           <div className='flex'>
            <div className="relative flex justify-center items-center h-[368px] mr-12 my-auto"> 
              <div className="absolute bg-slate-300 w-1 h-full"></div>
              <div className="absolute bg-slate-300 rounded-full w-8 h-8 mb-12"></div> 
            </div>
            {/* <div className="bg-blue-800 w-1 my-16 pb-12" /> */}
            <div className="bg-gray-800 shadow-md h-full md:h-96 xl:h-80 w-full md:w-[1000px] p-4 rounded-lg relative mb-12 ">
              <div className='flex-grow'>
                <img src={project.imageUrl} alt={`${project.name} Logo`} className={`${project.width} h-16 ml-6 mt-4`} />
                <div className="ml-6">
                  <h3 className="text-xl font-semibold mb-2 mt-2">{project.name}</h3>
                  <p className="text-white mb-2 text-justify mr-8">{project.description}</p>
                </div>
              </div>
              <div className='absolute bottom-0 left-0 mb-4 ml-4'>
                <div className='ml-6 flex flex-row flex-wrap items-start gap-1'>
                  {project.tech ? (
                    project.tech.map((tech, idx) => (
                      <span key={idx} className="inline-flex items-center rounded-md border px-2.5 py-1 
                        text-xs transition-colors focus:outline-none focus:ring-2 focus:ring-ring 
                        focus:ring-offset-2 border-transparent bg-gray-700 text-primary-foreground shadow hover:bg-primary/80">
                        {tech}
                      </span>
                    ))
                  ) : (
                    <span />
                  )}
                </div>
                <div className='mt-2 ml-6 flex flex-row flex-wrap items-start gap-1'>
                  {project.info ? (
                    project.info.map((info, idx) => (
                      <span key={idx} className="inline-flex items-center rounded-md border px-2.5 py-1 
                        text-xs transition-colors focus:outline-none focus:ring-2 focus:ring-ring 
                        focus:ring-offset-2 border-transparent bg-gray-950 text-primary-foreground shadow hover:bg-primary/80">
                        <GlobeIcon className="h-4 w-4 mr-1" />
                        {info}
                      </span>
                    ))
                  ) : (
                    <span />
                  )}
                </div>
              </div>
            </div>
          </div>
          </div>
        ))}
      </div>
    </div>

  )
}

export default transition(ProjectDetail);