import React, { useState, useEffect } from 'react';
import { FaRedoAlt } from 'react-icons/fa';
import { stories } from './stories.js';
import transition from '../../lib/transition.js';


const TypeRacer: React.FC = () => {
    const [typedText, setTypedText] = useState('');
    const [startTime, setStartTime] = useState<number | null>(null);
    const [wordCount, setWordCount] = useState(0);
    const [timer, setTimer] = useState(15);
    const [correctChars, setCorrectChars] = useState(0);
    const [totalTypedChars, setTotalTypedChars] = useState(0);
    const [gameEnded, setGameEnded] = useState(false);
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
    const [cursorPosition, setCursorPosition] = useState(0);
    const [isTyping, setIsTyping] = useState(false);
    const [sampleText, setSampleText] = useState(stories[Math.floor(Math.random() * stories.length)]);
    
    useEffect(() => {
        if (typedText.length === 1 && startTime === null && !gameEnded) {
            setStartTime(Date.now());
            const interval = setInterval(() => {
                setTimer(prev => {
                    if (prev > 1) return prev - 1;
                    clearInterval(interval);
                    setGameEnded(true);
                    return 0;
                });
            }, 1000);
            setIntervalId(interval); // Save interval ID to clear it later
        }
        setCursorPosition(typedText.length);
    
        // Detect if the user is typing
        if (typedText.length > 0) {
            setIsTyping(true);
            const typingTimeout = setTimeout(() => {
                setIsTyping(false);
            }, 500); // Adjust the timeout as needed
            return () => clearTimeout(typingTimeout);
        }
    }, [typedText, startTime, gameEnded]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputText = event.target.value;
        setTypedText(inputText);
        setWordCount(inputText.split(" ").filter(n => n !== '').length);
    
        // Calculate correct characters
        const correctCount = inputText.split('').reduce((acc, curr, idx) => {
            return acc + (curr === sampleText[idx] ? 1 : 0);
        }, 0);
        setCorrectChars(correctCount);
    
        // Update total typed characters
        setTotalTypedChars(prev => prev + 1);
    };
    
    

    const calculateWPM = () => {
        if (startTime) {
            const elapsedTime = (Date.now() - startTime) / 60000; // convert time to minutes
            return Math.round(wordCount / elapsedTime);
        }
        return 0;
    };

    const accuracy = totalTypedChars > 0 ? (correctChars / totalTypedChars) * 100 : 0;

    const restartGame = () => {
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
        }
        setTypedText('');
        setStartTime(null);
        setWordCount(0);
        setTimer(15);
        setCorrectChars(0);
        setTotalTypedChars(0);
        setGameEnded(false);
        setSampleText(stories[Math.floor(Math.random() * stories.length)]);
    };

    return (
        <div className="max-h-screen bg-main-colour overflow-hidden ">
            <div className='text-white bg-gray-800 w-[1200px] mx-auto rounded p-8 z-10 mt-36 mb-48'>
                {/* <h1 className="text-xl font-bold mb-3 ml-2 cursor-pointer-none">TypeRacer Game</h1> */}
                <p className="mt-3 text-orange-300 text-xl ml-2">{timer}</p>
                <div className="relative text-2xl text-justify ml-2">
                    <input
                        className="absolute inset-0 opacity-0 cursor-pointer"
                        type="text"
                        value={typedText}
                        onChange={handleInputChange}
                        placeholder="Start typing..."
                        disabled={gameEnded}
                        />
                    <div className="pointer-events-none">
                    {sampleText.split('').map((char, idx) => {
                        const typedChar = typedText[idx];
                        let className = '';
                        if (typedChar) {
                            className = typedChar === char ? 'text-green-500' : 'text-red-500';
                        }
                        return (
                            <span key={idx} className={className}>
                                {idx === cursorPosition && (
                                    <span className={isTyping ? "static-cursor" : "blinking-cursor"}></span>
                                )}
                                {char}
                            </span>
                        );
                    })}
                    </div>
                </div>
                <button onClick={restartGame} className="mt-3 text-white px-4 py-2 rounded flex items-center justify-center mx-auto outline-none">
                    <FaRedoAlt size={24} />
                </button>
                {gameEnded && (
                <>
                <div className='flex justify-center gap-4 text-xl'>
                <p className="mt-3">WPM: {typedText.length > 0 ? calculateWPM() : 0}</p>
                <p className="mt-3">Accuracy: {accuracy.toFixed(2)}%</p>
                </div>
                </>
                )}
            </div>
            <div className="absolute bottom-0 left-0 w-full h-[30vh] z-5 overflow-hidden z-0">
                <img src="/images/background/layered-waves-3.svg" alt="Layered Waves" className="w-full h-full object-cover object-bottom transform" />
            </div>
        </div>
    );
};

export default transition(TypeRacer);
